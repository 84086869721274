import { z } from 'zod'

export const PasswordSchema = z
	.string({ required_error: 'Password is required' })
	.min(8, { message: 'Password must be at least 8 characters' })
	.max(100, { message: 'Password is too long' })
export const FirstNameSchema = z
	.string({ required_error: 'First name is required' })
	.min(2, { message: 'First name is too short' })
	.max(40, { message: 'First name is too long' })
export const LastNameSchema = z
	.string({ required_error: 'Last name is required' })
	.min(2, { message: 'Last name is too short' })
	.max(40, { message: 'Last name is too long' })
export const EmailSchema = z
	.string({ required_error: 'Email is required' })
	.email({ message: 'Email is invalid' })
	.min(3, { message: 'Email is too short' })
	.max(100, { message: 'Email is too long' })
	// users can type the email with any casing, but we store it in lowercase
	.transform(value => value.toLowerCase())

export const SSNSchema = z
	.string({ required_error: 'SSN is required' })
	// SSN is 9 digits, but we allow two dashes somewhere in the string
	.min(11, { message: 'SSN must be 9 digits' })
	.max(11, { message: 'SSN must be 9 digits' })

export const PasswordAndConfirmPasswordSchema = z
	.object({ password: PasswordSchema, confirmPassword: PasswordSchema })
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'The passwords must match',
			})
		}
	})

export const ProfileFormSchema = z.object({
	firstName: FirstNameSchema.optional(),
	lastName: LastNameSchema.optional(),
	streetAddress1: z.string().optional(),
	streetAddress2: z
		.string()
		.optional()
		.nullable()
		.transform(value => value ?? null), // handle deleting a value after it was already saved
	city: z.string().optional(),
	state: z.string().optional(),
	postalCode: z
		.string()
		.max(5, { message: 'Must be 5 or fewer characters long' })
		.optional(),
})

// User data needed to sync from CRM
export const CRMPayloadSchema = z.object({
	firstName: FirstNameSchema,
	lastName: LastNameSchema,
	email: EmailSchema,
	ssn: SSNSchema,
	dateOfBirth: z.string().or(z.date()),
})

export type CRMPayload = z.infer<typeof CRMPayloadSchema>
